<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12 sm8 >
        <v-layout row wrap>
          <v-flex xs12 text-end pt-6 pr-12 v-if="!$vuetify.breakpoint.xs">
            <div>
              <v-chip outlined color="primary">
                <div>🎉&nbsp;<b>&nbsp;v4 is now available! Faster and even more accurate.</b></div>
              </v-chip>
            </div>
          </v-flex>
          <v-flex xs12 pt-6 pl-12 v-if="$vuetify.breakpoint.xs">
            <div>
              <v-chip outlined color="primary">
                <div>🎉&nbsp;<b>&nbsp;v4 is now available!</b></div>
              </v-chip>
            </div>
            <div class="mt-3">
              <v-chip outlined color="primary">
                <div><b>Faster and even more accurate.</b></div>
              </v-chip>
            </div>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12>
            <v-layout row wrap>
              <v-flex xs12 :class="{'mt-12': $vuetify.breakpoint.smAndUp, 'pl-6': !$vuetify.breakpoint.md, 'pr-6 mt-6': $vuetify.breakpoint.xs}" ml-6 mt-2>
                <h1 style="font-size:4.5em; line-height:75px">CV Parser Premium</h1>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 :class="{'pl-6': !$vuetify.breakpoint.md, '': $vuetify.breakpoint.xs}" ml-6 mt-10 pt-7>
            <h3 class="mb-1" style="font-size:2em;">Radically accurate.</h3>
            <h3 class="mb-1" style="font-size:2em;">Radically cheap.</h3>
            <h3 style="font-size:2em;">Radically easy to integrate.</h3>
          </v-flex>
          <v-flex xs12 text-center pt-12 pb-12 mt-7 :class="{'pr-8': $vuetify.breakpoint.smAndUp }">
            <v-btn large outlined class="primary white--text" to="/signup">
              Sign up to get free trial
            </v-btn>
            <div class="mt-1"><small><i>No credit card required.</i></small></div>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12 style="margin-top:20px" :class="{'white--text pl-6': $vuetify.breakpoint.xs, 'pl-7': $vuetify.breakpoint.smAndUp }">
            <div :style="`position: absolute; bottom: ${$vuetify.breakpoint.xs ? 8 : 8}px`">
              <small>
                <div>
                  <span>&copy; Copyright {{ new Date().getFullYear() }} – CV Parser Premium – GDPR compliant.</span>
                  <br v-if="$vuetify.breakpoint.xs" />
                  <a class="ml-2" href="/terms_and_conditions.html" target="_blank" :class="{'white--text': $vuetify.breakpoint.xs}">Terms and conditions</a> – 
                  <a class="" href="/privacy_policy.html" target="_blank" :class="{'white--text': $vuetify.breakpoint.xs}">Privacy policy</a> – 
                  <a class="" href="/cookie_policy.html" target="_blank" :class="{'white--text': $vuetify.breakpoint.xs}">Cookie policy</a>.
                </div>
              </small>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex xs12 sm4 style="background-color:black; min-height:100vh">
        <v-layout row wrap class="grey--text text--lighten-4" pt-12>
          <v-flex xs12 text-center pt-12 mt-2>
            <h1 style="font-size:3.1em;">Try it</h1>
          </v-flex>
          <v-flex xs12>
            <try-it-out-public/>
          </v-flex>
          <v-flex xs12 style="margin-top:100px" pl-6 :class="{'white--text': true}">
            <!-- <div style="position: absolute; bottom:45px"> -->
            <div :style="`position: absolute;  bottom: ${$vuetify.breakpoint.xs ? 75 : 8}px`">
              <small>
                <div>Made in Paris with A.I.</div>
                <div>Our goal is to bring efficiency to all HR companies around the world, no matter their size.</div>
                <div>First step: let's make precise CV data affordable to anyone.</div>
              </small>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>

import TryItOutPublic from '@/components/TryItOutPublic.vue';

export default {
  components: {
    TryItOutPublic
  },
  data: () => ({
    blobrUrl: "https://app.cvparser.ai/home"
  })
}
</script>

<style>
  .textAsLink {
    text-decoration: underline !important;
    cursor: pointer !important
  }
</style>